import Parse from 'parse';
import BaseModel from './BaseModel';
import User from './User';
import Noti from './Noti';
import _ from 'underscore';
import { geocodeByAddress } from 'react-places-autocomplete';
import config from '../config';
import { log } from './Log';
import sAlert from 'react-s-alert';
import tzLookup from 'tz-lookup';
import regeneratorRuntime from 'regenerator-runtime';
// import moment from 'moment-timezone';
import moment from '../config/momentConfig';
log('NOTI', Noti);

const repetitionOptions = {
  ONCE: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3
}

const sessionTypes = {
  MEDITATION: 0,
  YOGA: 1,
  TAI_CHI: 2,
  MUSIC: 3,
  PILATES: 4
}

const priceTypes = {
  FREE: 0,
  // PRICE_RANGE: 1,
  MEDIDATE_PRICING: 2
};

const joinSessionScene = {
  IN_SESSION: 0,
  POLICY_RANGE: 1,
  FULL: 2,
  FREE: 3,
  PAID_PLAN: 4,
  PAID_PLAN_FROZEN: 5,
  PAID_NO_PLAN_ALLOWS_BYPASS: 6,
  PAID_NO_PLAN_PAYMENTS_DROPIN: 7,
  PAID_NO_PLAN_PAYMENTS_NO_DROPIN: 8,
  PAID_NO_PLAN_NO_PAYMENTS: 9,
  ERROR: 10,
  USER_ADDED_TO_SESSION: 11,
  PAID_PLAN_EXCEEDS_WEEKLY_ENTRIES: 12,
  POLICY_FORMS: 13,
  DOUBLE_CLICK_ON_JOIN: 14,
  DOUBLE_CLICK_ON_LEAVE: 15
};

const leaveSessionScene = {
  NOT_IN_SESSION: 0,
  POLICY_RANGE: 1,
  FREE: 2,
  PAID_PLAN: 3,
  PAID_DROPIN: 4,
  PAID_NO_PLAN_NO_PAYMENT: 5,
  ERROR: 6,
  USER_REMOVED_FROM_SESSION: 7,
  PAID_DROPIN_MANUALLY: 8,
  POLICY_RANGE_ONLY_MESSAGE: 9
};

const sessionAction = {
  SHOW_MESSAGE: 0,
  ADVANCED: 1
};

var subscription;

var Session = class Session extends BaseModel {
  constructor() {
    super();
    this.sessions = [];
    this.repetitionOptions = repetitionOptions;
    this.joinSessionScene = joinSessionScene;
    this.leaveSessionScene = leaveSessionScene;
    this.sessionAction = sessionAction;
    this.sessionTypes = sessionTypes;
    this.priceTypes = priceTypes;
    this.attenders = [];
    this.unrefundedAttenders = [];
  }

  current() {
    return this.currentSession;
  }

  maxSessionsPerDay(sessions, maxSessionsPerDay) {

    var sessionsPerDay = 1;
    log('maxSessionsPerDay', sessionsPerDay, maxSessionsPerDay);
    try {
      _.map(sessions, (session, i) => {
        if (i === 0) {
          return;
        }
        var sessionAttr = session.attributes;
        var sessionLocation = session.get("location");

        var prevSession = sessions[i - 1];
        var prevSessionAttr = prevSession.attributes;
        var prevSessionLocation = prevSession.get("location");

        var sessionDate = new Date(this.getMomentDateByTimezone(sessionAttr.date, sessionLocation.latitude, sessionLocation.longitude));
        var prevSessionDate = new Date(this.getMomentDateByTimezone(prevSessionAttr.date, prevSessionLocation.latitude, prevSessionLocation.longitude));
        if (sessionDate.getDate() === prevSessionDate.getDate()) {
          sessionsPerDay++;
          if (sessionsPerDay > maxSessionsPerDay) {
            maxSessionsPerDay = sessionsPerDay;
          }
        } else {
          sessionsPerDay = 1;
        }
      });
    } catch (error) {
      log('maxSessionsPerDay error', error);
    }
    return maxSessionsPerDay;
  }

  getMomentDateByTimezone(date, lat, lon) {
    var timezone = tzLookup(lat, lon);
    return moment.tz(date, timezone);
  }


  getSessionsAttendersCount(props, callback) {
    Parse.Cloud.run('getSessionsAttendersCount',
      {
        sessionObjectIds: props.sessionObjectIds
      }, {
      success: (res) => {
        log("getSessionsAttendersCount", res);
        callback(null, res);

      },
      error: (error) => {
        log('error', error);
        callback(error, false);
      }
    });
  }

  getTeacherSessions(props, callback) {
    return new Promise(async (resolve, reject) => {
      var Session = Parse.Object.extend('MSession');

      var query = new Parse.Query(Session);

      let teacher = props.teacher ? props.teacher : Parse.User.current()
      query.equalTo('session_creator', teacher);

      query.ascending('date');
      if (props.fromDate && props.toDate) {
        query.greaterThanOrEqualTo('date', props.fromDate);
        query.lessThan('date', props.toDate);
      }

      query.include('session_teacher');
      query.limit(1000);
      query.find({
        success: (results) => {
          log('success')
          this.sessions = results;//.sort(function(session1,session2){return session1.get('date').getTime() - session2.get('date').getTime()});
          resolve(results);
          if (callback) {
            callback(null, results)
          }
        },
        error: function (error) {
          resolve([]);
          if (callback) {
            callback(error, null)
          }
        }
      });
    });
  }

  getSessionsPaidState(sessions, callback) {
    var sessionsObjectIdsArray = sessions.map((session) => {
      return session.id;
    });
    log('sessionsObjectIdsArray', sessionsObjectIdsArray);

    Parse.Cloud.run('getSessionsListPaidForStatus', { sessionsObjectIdsArray }, {
      success: function (result) {
        callback(null, result);
      },
      error: function (error) {
        callback(error);
      }
    });
  }


  getSessionsWithUnrefundedStudents(sessions, callback) {
    var sessionsObjectIdsArray = sessions.map((session) => {
      return session.id;
    });

    Parse.Cloud.run('getSessionsWithUnrefundedList', { sessionsObjectIdsArray }, {
      success: function (result) {
        callback(null, result);
      },
      error: function (error) {
        callback(error);
      }
    });
  }

  async getSessionWithAttenders(teacherId, fromDate, toDate) {
    return new Promise(async (resolve, reject) => {
      try {
        log('getSessionWithAttenders');
        const Session = Parse.Object.extend('MSession');
        const query = new Parse.Query(Session);
        query.equalTo('session_creator', Parse.Object.extend('_User').createWithoutData(teacherId));
        if (fromDate) {
          query.greaterThanOrEqualTo('date', fromDate);
        }
        if (toDate) {
          query.lessThanOrEqualTo('date', toDate);
        }
        query.greaterThan('attenders_count', 0);
        const sessions = await query.find();
        const result = sessions;
        resolve(result.length);
      } catch (error) {
        log('getSessionWithAttenders error', error);
        reject([]);
      }
    });
  }

  async getPaidSessions(teacherId, fromDate, toDate, getStudents) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await Parse.Cloud.run('getPaidSessionsWithoutReceipt', { teacherId, fromDate, toDate });
        log('getPaidSessions result', result);
        const result1 = result.sessions;
        const result2 = result.sessionsCounter;

        if (getStudents) {
          log('getPaidSessions getStudents');
          const Session = Parse.Object.extend('MSession');
          const query = new Parse.Query(Session);
          query.containedIn('objectId', [...result1, ...result2]);
          let totalSessions = await query.find();
          let totalStudents = 0;
          for (let i = 0; i < totalSessions.length; i++) {
            totalStudents += totalSessions[i].get('attenders_count');
          }
          log('getPaidSessions totalStudents', totalStudents);
          resolve(totalStudents);
        } else {
          const finalResult = result1.length + result2.length;
          log('getPaidSessions finalResult', finalResult);
          resolve(finalResult);
        }
      } catch (error) {
        log('getPaidSessions error', error);
        if (getStudents) {
          reject(0);
        } else {
          reject([]);
        }
      }
    });
  }

  async getPaidSessionsWithoutReceipt(teacherId, fromDate, toDate) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await Parse.Cloud.run('getPaidSessionsWithoutReceipt', { teacherId, fromDate, toDate }).sessions;
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getPaidSessionsWithReceipt(teacherId, fromDate, toDate) {
    log('getPaidSessionsWithReceipt');
    return new Promise(async (resolve, reject) => {
      try {
        const result = await Parse.Cloud.run('getPaidSessionsWithReceipt', { teacherId, fromDate, toDate });
        log('getPaidSessionsWithReceipt result', result);
        const result1 = result.sessions;
        log('getPaidSessionsWithReceipt finalResult', result1);
        resolve(result1.length);
      } catch (error) {
        reject(error);
      }
    });
  }

  changeSessionPrivacyState(privateSession, currentSessionOnly, callback) {
    log('changeSessionPrivacyState', privateSession);
    var session = this.getCurrentSession();
    Parse.Cloud.run('changeSessionPrivacyState',
      {
        sessionObjectId: session.id,
        currentSessionOnly,
        privateSession
      }, {
      success: (res) => {
        log("setObjectPrivacy", res);
        callback(null, true);

      },
      error: (error) => {
        log('error', error);
        callback(error, false);
      }
    });
  }

  updateSessionPrivacy(privateSetting, callback) {
    log('updateSessionPrivacy');
    var session = this.getCurrentSession();
    log('updateprivacy: session', session);
    log('updateprivacy: session.getACL()', session.getACL() ? 'exists' : 'doesnt exists');
    log('updateprivacy: session.getACL().getPublicReadAccess()' + session.getACL().getPublicReadAccess());
    log('updateprivacy: attenders', this.attenders);

    var userIds = [];

    userIds.push(session.get('session_creator').id);

    if (session.get('session_teacher'))
      userIds.push(session.get('session_teacher').id);

    for (var i = 0; i < this.attenders.length; i++) {
      userIds.push(this.attenders[i].id);
    }
    Parse.Cloud.run('setObjectPrivacy',
      {
        objectId: session.id,
        isPrivate: privateSetting,
        exposedUsers: userIds,
        className: 'MSession'
      }, {
      success: (res) => {
        log("setObjectPrivacy", res);
        callback(null, true);

      },
      error: (error) => {
        log('error', error);
        callback(error, false);
      }
    });
  }

  getUpcomingSessions(teacherId, callback) {
    var Session = Parse.Object.extend('MSession');
    var query = new Parse.Query(Session);
    query.equalTo('session_creator', teacherId ? Parse.User.createWithoutData(teacherId) : Parse.User.current());
    query.ascending('date')
    query.greaterThanOrEqualTo('date', new Date());
    query.notEqualTo('canceled', true);
    query.limit(1000);
    query.find({
      success: (results) => {
        log('success')
        callback(null, results)
        this.sessions = results;
      },
      error: function (error) {
        callback(error, null)
      }
    });
  }

  sendSessionInfoToTeacher(callback) {
    // sendSessionInfoEmailToTeacher
    Parse.Cloud.run('sendSessionInfoEmailToTeacher', { sessionId: this.current().id }, {
      success: function (result) {
        log('success sendSessionInfoEmailToTeacher');
        callback(null, result);
      },
      error: function (error) {
        log('error', error);
        callback(error, null)
      }
    });
  }


  setCurrentSession(sessionObject) {
    this.currentSession = sessionObject;
  }

  getCurrentSession() {
    return this.currentSession;
  }

  duplicateCurrentSession() {
    var newSession = this.currentSession.clone();
    newSession.set("attenders_count", 0);
    newSession.unset("waiting_list");
    newSession.unset("frequent_students");
    newSession.unset("frequent_students_added");
    newSession.unset("frequent_students_auto_punched");
    newSession.unset("recurred_session");
    newSession.unset("online_session_notified");
    newSession.unset("session_notified");

    if (!newSession.get('online_session_one_url')) {
      newSession.unset("online_session_url");
      newSession.unset("online_session_password");
    }

    this.setCurrentSession(newSession);
    return this.currentSession;
  }


  getSessionById(sessionId, teacherId, callback) {
    var cachedSession = _.find(this.sessions, (sessionObj) => {
      return sessionObj.id === sessionId;
    })


    if (cachedSession) {
      window.setTimeout(() => {
        callback(null, cachedSession)
      }, 100)
      return;
    }

    log('getSessionById sessionId', sessionId)
    log('getSessionById teacherId', teacherId)

    var Session = Parse.Object.extend('MSession');
    var query = new Parse.Query(Session);
    query.equalTo('session_creator', teacherId ? Parse.User.createWithoutData(teacherId) : Parse.User.current());
    query.equalTo('objectId', sessionId);
    query.include('frequent_students');
    query.include('session_creator');
    query.include('session_creator.preferences');
    query.include('session_teacher');
    query.notEqualTo('canceled', true);
    query.find({
      success: (results) => {
        log('getSessionById success', results)
        callback(null, results[0])
      },
      error: function (error) {
        callback(error, null)
      }
    });
  }

  cancelSession(callback) {
    this.updateSession({ canceled: true }, callback);
  }

  updateSession(props, callback) {
    if (!this.current()) {
      return;
    }

    log('updateSession', props)
    var currentSession = this.current();

    var privacyChanged = props.privacyChanged;
    delete props.privacyChanged;

    var privateSession = props.privateSession;
    delete props.privateSession;

    var updateAll = props.updateAll;
    delete props.updateAll;

    if (!props.end_date) {
      log('unset end_date')
      currentSession.unset('end_date');
      delete props.end_date;
    }
    if (props.refreshWaitingList) {
      log('updateSession props.refreshWaitingList', props.refreshWaitingList);
      delete props.waiting_list_sent;
      delete props.waiting_list_done;
      currentSession.unset('waiting_list_sent');
      currentSession.unset('waiting_list_done');
      delete props.refreshWaitingList;
    }

    currentSession.save(props, {
      success: (session) => {
        log('session update success')

        if (updateAll) {
          log('all sessions update success')
          Parse.Cloud.run('saveUpdatedInfoToFutureRecurringSessions', { sessionObjectId: currentSession.id }, {
            success: (result) => {
              log('success saveUpdatedInfoToFutureRecurringSessions');

              if (privacyChanged) {
                this.changeSessionPrivacyState(privateSession, false, (err, success) => {
                });
              }
            },
            error: function (error) {
              log('error', error);
            }
          });
        } else if (privacyChanged) {
          this.changeSessionPrivacyState(privateSession, true, (err, success) => {
          });
        }

        callback(null, session);
      },
      error: function (error) {
        log('Failed to update object', JSON.stringify(error));
        callback(error);
      }
    });
  }

  addNewSession(callback) {
    var Session = Parse.Object.extend("MSession");
    var session = new Session();
    var defaultAddress = Parse.User.current().get('city') ?
      (Parse.User.current().get('city') + ', ' + Parse.User.current().get('country')) :
      'Tel Aviv, Israel';
    geocodeByAddress(defaultAddress, (err, latlon) => {
      log(err, latlon);
      session.save({
        session_creator: Parse.User.current(),
        location: latlon ? new Parse.GeoPoint({ latitude: latlon.lat, longitude: latlon.lng }) : undefined,
        address: defaultAddress,
        session_teacher: Parse.User.current(),
        currency: User.getCurrencyByBankCountry(Parse.User.current())
      }, {
        success: function (res) {
          log('session create success', res)
          // Noti.sendSessionCreatedPushNotification(res);
          callback(null, res);
        },
        error: function (error) {
          log('Failed to create object, with error code: ' + error.message);
          callback(error);
        }
      });
    });
  }

  getCachedSessionAttenders() {
    return this.attenders
  }

  getSessionAttenders(callback, limit) {
    log('getSessionAttenders');
    log('getSessionAttenders callback', callback);
    log('getSessionAttenders limit', limit);
    if (!this.current()) {
      return;
    }

    var querySessionAttenders = this.current().relation("attenders").query();
    if (limit && (limit instanceof Number))
      querySessionAttenders.limit(limit)

    querySessionAttenders.find({
      success: (results) => {

        log('this.current().get(attenders_count)', this.current().get('attenders_count'));
        log('results.length', results.length);
        if (this.current().get('attenders_count') != results.length) {
          this.normalizeAttendersCountFromAttendersRelation();
        }
        callback(null, results)
        this.attenders = results;
      },
      error: (error) => {
        callback(error, null)
        log(error)
      }
    });
  }

  getUnrefundedSessionAttenders(callback, limit) {
    log('getUnrefundedSessionAttenders');
    if (!this.current()) {
      return;
    }

    var querySessionAttenders = this.current().relation("unrefunded_attenders").query();
    if (limit)
      querySessionAttenders.limit(limit)

    querySessionAttenders.find({
      success: (results) => {

        // log('this.current().get(attenders_count)', this.current().get('attenders_count'));
        // log('results.length', results.length);
        // if (this.current().get('attenders_count') != results.length) {
        //   this.normalizeAttendersCountFromAttendersRelation();
        // }
        callback(null, results)
        this.unrefundedAttenders = results;
      },
      error: (error) => {
        callback(error, null)
        log(error)
      }
    });
  }

  normalizeAttendersCountFromAttendersRelation() {
    log('normalizeAttendersCountFromAttendersRelation');
    Parse.Cloud.run('normalizeAttendersCountFromAttendersRelationServer', { sessionIds: [this.current().id] }, {
      success: function (result) {
        log('success normalizeAttendersCountFromAttendersRelationServer')
      },
      error: function (error) {
        log('error normalizeAttendersCountFromAttendersRelationServer', error);
      }
    });
  }

  getSingleSessionAttender(student, callback) {
    if (!this.current()) {
      return;
    }

    var querySessionAttenders = this.current().relation("attenders").query();
    querySessionAttenders.equalTo("objectId", student.id);
    querySessionAttenders.find({
      success: (attender) => {
        callback(null, attender[0])
      },
      error: (error) => {
        callback(error, null)
        log(error)
      }
    });
  }

  getSingleSessionUnrefundedAttender(student, callback) {
    if (!this.current()) {
      return;
    }

    var querySessionAttenders = this.current().relation("unrefunded_attenders").query();
    querySessionAttenders.equalTo("objectId", student.id);
    querySessionAttenders.find({
      success: (attender) => {
        callback(null, attender[0])
      },
      error: (error) => {
        callback(error, null)
        log(error)
      }
    });
  }

  getSessionWaitingList(callback) {
    if (!this.current()) {
      return;
    }

    callback(null, this.current().get('waiting_list'));
  }

  getSpecificSessionAttenders(callback, session) {
    if (!session) {
      return;
    }

    var querySessionAttenders = session.relation("attenders").query();
    querySessionAttenders.find({
      success: (results) => {
        callback(null, results)
        this.attenders = results;
      },
      error: (error) => {
        callback(error, null)
        log(error)
      }
    });
  }

  getFilterSessionAttenders(filteredStudents, callback) {
    if (!this.current()) {
      return;
    }

    var userIds = [];
    for (var i = 0; i < filteredStudents.length; i++) {
      userIds.push(filteredStudents[i].id);
    }

    var querySessionAttenders = this.current().relation("attenders").query();
    querySessionAttenders.containedIn('objectId', userIds);
    querySessionAttenders.find({
      success: (results) => {
        callback(null, results)
        this.attenders = results;
      },
      error: (error) => {
        callback(error, null)
        log(error)
      }
    });
  }

  forceLeaveSession(userObject, callback) {
    if (!this.current()) {
      return;
    }
    var session = this.current();
    //Remove from waiting list
    Parse.Cloud.run('leaveSession', { userObjectId: userObject.id, sessionObjectId: session.id, forceLeave: true }, {
      success: function (result) {
        log('success Force LeaveSession', result);
        callback(null, result);
        this.attenders = _.filter(this.attenders, (item) => item.id !== userObject.id)

        session.fetch().then((session) => {
          if (session.getACL() && !session.getACL().getPublicReadAccess()) {
            log('Need updating')
            this.updateSessionPrivacy(true);
          }
        }, (error) => {
          //did not refresh successfully
          if (session.getACL() && !session.getACL().getPublicReadAccess()) {
            log('Need updating')
            this.updateSessionPrivacy(true);
          }
        });
      },
      error: function (error) {
        log('error', error);
      }
    });
  }

  removeAttender(userObject, callback) {
    log('removeAttender');
    if (!this.current()) {
      return;
    }
    var session = this.current();
    var sessionAttendersRelations = session.relation("attenders");
    sessionAttendersRelations.remove(userObject);
    session.increment('attenders_count', -1);
    session.unset('waiting_list_done');
    session.unset('waiting_list_sent');
    session.save({}, {
      success: (res) => {
        Parse.Cloud.run('logSessionActivity', { studentId: userObject.id, sessionId: session.id, actionType: 2 });
        callback(null, res);
        this.attenders = _.filter(this.attenders, (item) => item.id !== userObject.id)
        session.fetch().then((session) => {
          if (session.getACL() && !session.getACL().getPublicReadAccess()) {
            log('Need updating')
            this.updateSessionPrivacy(true);
          }
        }, (error) => {
          //did not refresh successfully
          if (session.getACL() && !session.getACL().getPublicReadAccess()) {
            log('Need updating')
            this.updateSessionPrivacy(true);
          }
        });

      },
      error: (err) => {
        callback(err, null);
        log('error', err)
      }
    });
  }

  addAttender(userObject, callback) {
    if (!this.current()) {
      return;
    }
    var session = this.current();
    User.addFollower(userObject);

    Parse.Cloud.run('joinSession', { userObjectId: userObject.id, sessionObjectId: session.id }, {
      success: (result) => {
        log('success joinSession', result);
        switch (result.scenario) {
          case 3:
          case 4: {
            if (result.scenario != 3) {
              sAlert.success(showTextByBusinessType('session.ENTERED_WITH_PLAN'));
            }
          }
          case 6:
            this.attenders = [...this.attenders, userObject];
            callback(null, userObject);

            session.fetch().then((session) => {
              if (session.getACL() && !session.getACL().getPublicReadAccess()) {
                log('Need updating')
                this.updateSessionPrivacy(true);
              }
            }, (error) => {
              //did not refresh successfully
              if (session.getACL() && !session.getACL().getPublicReadAccess()) {
                log('Need updating')
                this.updateSessionPrivacy(true);
              }
            });
            break;

          default:
            //Force Join
            Parse.Cloud.run('addUserToSessionWebhook', { userObjectId: userObject.id, sessionObjectId: session.id }, {
              success: (result) => {
                log('success addUserToSessionWebhook', result);
                this.attenders = [...this.attenders, userObject];
                callback(null, userObject);

                session.fetch().then((session) => {
                  if (session.getACL() && !session.getACL().getPublicReadAccess()) {
                    log('Need updating')
                    this.updateSessionPrivacy(true);
                  }
                }, (error) => {
                  //did not refresh successfully
                  if (session.getACL() && !session.getACL().getPublicReadAccess()) {
                    log('Need updating')
                    this.updateSessionPrivacy(true);
                  }
                });

              },
              error: (error) => {
                log('error', error);
                callback(error);
              }
            });
            break;
        }

      },
      error: (error) => {
        log('error', error);
        callback(error);
      }
    });

    return;
  }

  addUnrefundedAttender(userObject, callback) {
    if (!this.current()) {
      return;
    }
    var session = this.current();
    var sessionAttendersRelations = session.relation("attenders");
    sessionAttendersRelations.remove(userObject);

    //Remove user from unrefunded_attenders if needed
    var sessionUnrefundedAttendersRelations = session.relation("unrefunded_attenders");
    sessionUnrefundedAttendersRelations.add(userObject);

    session.increment('attenders_count', -1);

    session.save({}, {
      success: (res) => {
        log('success addUnrefundedAttender')
        // this.attenders = [...this.attenders, userObject];
        this.attenders = _.filter(this.attenders, (item) => item.id !== userObject.id);

        var statusParams = {
          userIds: [userObject.id],
          sessionObjectId: session.id,
          sessionCreatorObjectId: Parse.User.current().id,
          inDebug: config.ENV === 'development'
        }

        this.getUsersPaymentStatusForSession(statusParams, (err, result) => {
          if (err) {
            log('error getUsersPaymentStatusForSession', err);
            callback(null, userObject);
          } else {
            log('getUsersPaymentStatusForSession result', result);
            var userResult = result[userObject.id];
            var userPlan = userResult ? userResult['userPaymentPlan'] : undefined;
            log('getUsersPaymentStatusForSession userPlan', userPlan);
            if (userResult && userPlan && userResult['paid']) {
              log('getUsersPaymentStatusForSession userResult | userPlan | userResult all true');
              var enteredSessionsRelation = userPlan.relation('entered_sessions');
              enteredSessionsRelation.remove(session);
              var unRefundedsessionsRelation = userPlan.relation('unrefunded_sessions');
              unRefundedsessionsRelation.add(session);

              userPlan.save({}, {
                success: function (res) {
                  callback(null, userObject);
                },
                error: function (error) {
                  callback(error, null);
                }
              });
            } else {
              callback(null, userObject);
            }
          }
        });

        session.fetch().then((session) => {
          if (session.getACL() && !session.getACL().getPublicReadAccess()) {
            log('Need updating')
            this.updateSessionPrivacy(true);
          }
        }, (error) => {
          //did not refresh successfully
          if (session.getACL() && !session.getACL().getPublicReadAccess()) {
            log('Need updating')
            this.updateSessionPrivacy(true);
          }
        });

      },
      error: (err) => {
        callback(err, null);
        log('error', err)
      }
    });
  }

  addUserToWaitingList(userObject, callback) {
    if (!this.current()) {
      return;
    }
    var session = this.current();

    //Add to waiting list
    Parse.Cloud.run('addUserToWaitingList', { userObjectId: userObject.id, sessionObjectId: session.id }, {
      success: function (result) {
        log('Added to waiting list');
        session.fetch().then((session) => {
          callback(null, userObject);
        });

      },
      error: function (error) {
        log('error', error);
        callback(error, userObject);
      }
    });

  }

  removeUserFromWaitingList(userObject, callback) {
    if (!this.current()) {
      return;
    }
    var session = this.current();
    //Remove from waiting list
    Parse.Cloud.run('removeUserFromWaitingList', { userObjectId: userObject.id, sessionObjectId: session.id }, {
      success: function (result) {
        log('Removed from waiting list');
        session.fetch().then((session) => {
          callback(null, userObject);
        });
      },
      error: function (error) {
        log('error', error);
        callback(error, userObject);
      }
    });

  }

  getPlansSessionRelationForSession(callback) {
    this.current().fetch({
      success: function (session) {
        log('valid_plans', session.get('valid_plans'));
        if (!session.get('valid_plans')) {
          return null;
        }

        var planSessionRelationsHash = {};
        session.get('valid_plans').map((plan) => {
          planSessionRelationsHash[plan.id] = true;
        });
        callback(null, planSessionRelationsHash);
      }
    });
  }

  transformPlans(planSessionRelations) {
    var formattedPlanSessionRelations = {};

    planSessionRelations.map((planSessionRelation) => {
      var plan = planSessionRelation.get('ticket') || planSessionRelation.get('membership');
      if (!plan) {
        log('transformPlans no plan')
        return;
      }

      var p = _.find(plans, (statePlan) => {
        return statePlan.id === plan.id
      });

      if (p) {
        planSessionRelations[p.id] = true;
      }

    });
  }

  toggleAddRemovePlanToSession(plan, callback) {
    if (!this.current()) {
      return;
    }
    var currentSession = this.current();
    currentSession.unset('frequent_students_auto_punched');
    currentSession.save({}, {
      success: function (res) {
        console.log('Saved frequent_students_auto_punched to true');
      },
      error: function (error) {
        console.log('Failed to save frequent_students_auto_punched to true ' + error.message);
        // response.error(error);
      }
    });

    Parse.Cloud.run('updateValidPlansForSession', { planIds: [{ planId: plan.id, className: plan.className }], planClassName: plan.className, sessionObjectId: currentSession.id }, {
      success: (result) => {
        callback(null, result);
        log('success updateValidPlansForSession');
      },
      error: (error) => {
        log('error', error);
      }
    });

  }

  addFrequentStudent(student, callback) {
    log('addFrequentStudent');

    var currentSession = this.current();

    Parse.Cloud.run('updateFrequentStudentStatus', { userId: student.id, sessionObjectId: currentSession.id, shouldAdd: true }, {
      success: function (result) {
        log('success updateFrequentStudentStatus');
        currentSession.fetch().then((session) => {
          callback(true);
        }, (error) => {
          //did not refresh successfully
          callback(true);
        });

      },
      error: function (error) {
        log('error', error);
        callback(false);
      }
    });
  }

  removeFrequentStudent(student, callback) {
    log('removeFrequentStudent');

    var currentSession = this.current();

    Parse.Cloud.run('updateFrequentStudentStatus', { userId: student.id, sessionObjectId: currentSession.id, shouldAdd: false }, {
      success: function (result) {
        log('success updateFrequentStudentStatus');
        currentSession.fetch().then((session) => {
          callback(true);
        }, (error) => {
          //did not refresh successfully
          callback(true);
        });
        // callback(true);
      },
      error: function (error) {
        log('error', error);
        callback(false);
      }
    });
  }

  getUsersPaymentStatusForSession(params, callback) {
    log('getUsersPaymentStatusForSession')
    if (!this.current()) {
      return;
    }
    var p = {
      userIds: params.userIds,
      sessionObjectId: this.current().id,
      sessionCreatorObjectId: params && params.teacher ? params.teacher.id : Parse.User.current().id,
      inDebug: config.ENV === 'development',
      ...(params.unrefunded && {
        unrefunded: params.unrefunded,
      }),
    }

    log('banana', p)

    Parse.Cloud.run('getUsersPaymentStatusForSession', p, {
      success: function (result) {
        log('success getUsersPaymentStatusForSession')
        callback(null, result);
      },
      error: function (error) {
        log('error', error);
        callback(error, null);
      }
    });
  }

  getListOfSessionsPaidStatusForUser(params, callback) {
    log('getListOfSessionsPaidStatusForUser');
    var p = {
      sessionsObjectIdsArray: params.sessionIds,
      userId: params.userToCheckId,
      sessionCreatorObjectId: Parse.User.current().id,
      inDebug: config.ENV === 'development',
      ...(params.unrefunded && {
        unrefunded: params.unrefunded
      })
    }
    log("params " + p);

    Parse.Cloud.run('getSessionsListPaidStatusForSpecificUser', p, {
      success: function (result) {
        log('success getSessionsListPaidStatusForSpecificUser')
        callback(null, result);
      },
      error: function (error) {
        log('error', error);
        callback(error, null);
      }
    });
  }

  removeStudentFromUnrefundedStatusInSession(params, callback) {
    if (!params.userId) {
      params = { userId: params.id, sessionId: this.currentSession.id };
    }
    Parse.Cloud.run('removeStudentFromUnrefundedStatusInSession', params, {
      success: function (result) {
        log('success removeStudentFromUnrefundedStatusInSession')
        callback(null, result);
      },
      error: function (error) {
        log('error', error);
        callback(error, null);
      }
    });
  }

  subscribeToSessionChanges(callback) {
    try {
      if (this.current()) {
        log("subscribeToSessionChanges");
        var queryToSubscribe = new Parse.Query(Parse.Object.extend('MSession'));
        queryToSubscribe.equalTo('objectId', this.current().id);

        subscription = ParseLiveQueryClient.subscribe(queryToSubscribe);

        subscription.on('open', () => {
          log('socket connection opened');
        });

        subscription.on('close', () => {
          log('socket connection closed');
        });

        subscription.on('enter', (session) => {
          log('socket connection entered');
        });

        subscription.on('update', (session) => {
          log('Session Updated');
          log('Session Updated session attenders count', session.get("attenders_count"));
          callback(session);
        });
      }
    } catch (e) {
      log(e);
    }
  }

  unsubscribeToSessionChanges() {
    try {
      ParseLiveQueryClient.unsubscribe(subscription);
    } catch (error) {
      log("unsubscribeToChatChanges", error);
    }
  }
}

export default new Session();
