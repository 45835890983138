import Parse from 'parse';
import BaseModel from './BaseModel';
import _ from 'underscore';
import User from './User';
// import moment from 'moment';
import moment from '../config/momentConfig';
import config from '../config';
import { log } from './Log';
import regeneratorRuntime from 'regenerator-runtime';

const expirationTypes = {
	0: showTextByBusinessType('common.WEEKS'),
	1: showTextByBusinessType('common.MONTHS')
};

var Plan = class Plan extends BaseModel {
	constructor() {
		super();
		this.expirationTypes = expirationTypes;
	}

	current() {
		return this.currentPlan;
	}

	currentType() {
		return this.currentPlanType;
	}

	setCurrentPlan(plan) {
		this.currentPlan = plan;
		this.currentPlanType = plan.className;
	}

	async getStillActiveUserPaymentPlans(teacherId, daysUntilExpiration) {
		console.log('getStillActiveUserPaymentPlans', teacherId, daysUntilExpiration)
		const result = await Parse.Cloud.run('getStillActiveUserPaymentPlans', { teacherId, daysUntilExpiration });
		console.log('getStillActiveUserPaymentPlans', result)
		return result;
	}

	async getPlansWithPayments(teacherId, fromDate, toDate) {
		const result = await Parse.Cloud.run('getPlansWithPayments', { teacherId, fromDate, toDate });
		return result;
	}

	getRelatedUserPaymentPlans(teacherId, callback) {
		var UserPaymentPlan = Parse.Object.extend('UserPaymentPlan');
		var query = new Parse.Query(UserPaymentPlan);

		var owner;
		if (teacherId && teacherId !== Parse.User.current().id) {
			owner = new Parse.User({
				id: teacherId
			});
		} else {
			owner = Parse.User.current();
		}
		query.equalTo('owner', owner);
		query.exists('buyer');
		query.include('ticket');
		query.include('membership');
		query.include('buyer');
		query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
		query.include('payment.entries_used');
		query.include('payment_debug.entries_used');
		query.include('payment.payment_method_type');
		query.include('payment_debug.payment_method_type');
		// query.notEqualTo('is_medidate_membership', true);

		query.find({
			success: (res) => {
				log('success get all plans for user', res)

				var results = {
					finishedTicketsWithPunches: [],
					finishedTicketsWithoutPunches: [],
					frozenTickets: [],
					frozenMemberships: [],
					unpaidTickets: [],
					unpaidMemberships: []
				}

				res.forEach((upp) => {
					try {
						if (upp.get('ticket')) {
							if (upp.get('frozen')) {
								results.frozenTickets.push(this.transformStudentPaymentPlan(upp));
							} else if (!this.isUserPaymentPlanPaid(upp)) {
								results.unpaidTickets.push(this.transformStudentPaymentPlan(upp));
							} else if (this.isUserPaymentPlanDateEnded(upp) &&
								!this.isUserPaymentPlanPunchesEnded(upp)) {
								results.finishedTicketsWithPunches.push(this.transformStudentPaymentPlan(upp));
							} else if (this.isUserPaymentPlanDateEnded(upp) &&
								this.isUserPaymentPlanPunchesEnded(upp)) {
								results.finishedTicketsWithoutPunches.push(this.transformStudentPaymentPlan(upp));
							}
						} else if (upp.get('membership')) {
							if (upp.get('frozen')) {
								results.frozenMemberships.push(this.transformStudentPaymentPlan(upp));
							} else if (!this.isUserPaymentPlanPaid(upp)) {
								results.unpaidMemberships.push(this.transformStudentPaymentPlan(upp));
							}
						}
					} catch (e) {
						log(e)
					}
				});
				callback(null, results)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getPlans(planType, teacherId, callback) {
		return new Promise((resolve, reject) => {
			var Plan = Parse.Object.extend(planType);
			var query = new Parse.Query(Plan);

			query.equalTo('owner', teacherId ? Parse.User.createWithoutData(teacherId) : Parse.User.current())
			// query.ascending('updatedAt');
			query.descending('updatedAt');
			query.exists('title')
			if (teacherId) {
				query.equalTo('active', true)
				query.notEqualTo('archived', true)
			}

			query.find({
				success: (results) => {
					log('getPlans success', results)
					resolve(results)
					if (callback) {
						callback(null, results)
					}
				},
				error: function (error) {
					log('getPlans error', error)
					resolve([])
					if (callback) {
						callback(error, null)
					}
				}
			});
		})
	}

	isPlanHasStudents(plan, callback) {
		this.setCurrentPlan(plan);
		this.getPlanStudents((err, res) => {
			if (err) {
				callback(err);
			}
			return callback(null, !!res.length);
		})
	}

	isCurrentPlanHasStudents(callback) {
		this.getPlanStudents((err, res) => {
			if (err) {
				callback(err);
			}
			return callback(null, !!res.length);
		})
	}

	removePlan(plan, callback) {
		this.isPlanHasStudents(plan, (err, res) => {
			if (res === false) {
				plan.destroy({
					success: () => {
						callback(null);
					},
					error: (err) => {
						callback(err);
					}
				});
			} else {
				err = {
					message: "Can't remove plan with registered students"
				}
				callback(err)
			}
		})
	}

	togglePlanActiveState(plan, callback) {
		this.updatePlan(plan, { active: !plan.get('active') }, (err, res) => {
			callback(err, res);
		});
	}

	togglePlanArchived(plan, callback) {
		this.updatePlan(plan, { archived: !plan.get('archived') }, (err, res) => {
			callback(err, res);
		});
	}

	getPlanById(planType, planId, teacherId, callback) {
		var Plan = Parse.Object.extend(planType);
		var query = new Parse.Query(Plan);

		query.equalTo('owner', teacherId ? Parse.User.createWithoutData(teacherId) : Parse.User.current())
		query.equalTo('objectId', planId)
		query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');

		query.find({
			success: (results) => {
				log('success')
				var plan = results.length ? results[0] : {};
				this.setCurrentPlan(plan)
				callback(null, plan)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	addNewPlan(planType, callback) {
		if (!planType) {
			return
		}

		var Plan = Parse.Object.extend(planType);
		var plan = new Plan();

		plan.save({
			owner: Parse.User.current(),
			active: true,
			currency: User.getCurrencyByBankCountry(Parse.User.current())
		}, {
			success: function (res) {
				log('plan create success', res)
				callback(null, res);
			},
			error: function (error) {
				log('Failed to create object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	addNewPlanWithParams(planType, params, callback) {
		if (!planType) {
			return
		}

		log('addNewPlanWithParams', planType)
		log('addNewPlanWithParams', params)
		var Plan = Parse.Object.extend(planType);
		var plan = new Plan();
		//TODO add params of plan while Saving

		plan.save(params, {
			success: function (res) {
				log('plan with params create success', res)
				callback(null, res);
			},
			error: function (error) {
				log('Failed to create object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	updatePlan(plan, props, callback) {
		if (!plan) {
			return;
		}

		log('updateplan', props);

		plan.save(props, {
			success: function (plan) {
				log('plan update success')
				callback(null, plan);
			},
			error: function (error) {
				log('Failed to update object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	logManualyPunched(userPaymentPlan, props) {
		if (!userPaymentPlan) {
			return;
		}

		log('logManualyPunched with props', props);

		if (userPaymentPlan.get('manualy_punched')) {
			var manualyPunchedArray = userPaymentPlan.get('manualy_punched');
			manualyPunchedArray.push({ punched_count_before: props.punched_count_before, punched_count_after: props.punched_count_after, punched: props.punched, date: new Date() })
			userPaymentPlan.set('manualy_punched', manualyPunchedArray);

		} else {
			userPaymentPlan.set('manualy_punched', [{ punched_count_before: props.punched_count_before, punched_count_after: props.punched_count_after, punched: props.punched, date: new Date() }]);

		}
		// callback('Updated manualy_punched before save');
		// userPaymentPlan.save({}, {
		// 	success: function (userPaymentPlan) {
		// 		log('UserPaymentPlan update success', userPaymentPlan)
		// 		callback(null, userPaymentPlan);
		// 	},
		// 	error: function (error) {
		// 		log('Failed to update object, with error code: ' + error.message);
		// 		callback(error);
		// 	}
		// });
	}

	updateUserPaymentPlan(userPaymentPlan, props, callback) {
		if (!userPaymentPlan) {
			return;
		}

		userPaymentPlan.save(props, {
			success: function (userPaymentPlan) {
				log('UserPaymentPlan update success', userPaymentPlan)
				callback(null, userPaymentPlan);
			},
			error: function (error) {
				log('Failed to update object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	updateCurrentPlan(props, callback) {
		log('update', props, this.current())
		if (!this.current()) {
			return;
		}

		this.current().save(props, {
			success: function (session) {
				log('plan update success')
				callback(null, session);
			},
			error: function (error) {
				log(error)
				log('Failed to update object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	getPlansSessionRelationsForPlan(sessions, callback) {
		log('getPlansSessionRelationsForPlan', sessions.length);

		var planExistsInValidPlans = (plan) => {
			return plan.id === this.current().id;
		};

		Parse.Object.fetchAll(sessions, {
			success: (sessions) => {
				// All the objects were fetched.
				var planSessionRelations = [];
				for (let i = 0; i < sessions.length; i++) {
					var session = sessions[i];
					// log('session.get(valid_plans)', session.get('valid_plans'));
					// log('Some', session.get('valid_plans').some(planExistsInValidPlans));
					if (session.get('valid_plans') && session.get('valid_plans').some(planExistsInValidPlans)) {
						session.isInPlan = true;
					} else {
						// log('Plan not in session');
						session.isInPlan = false;
					}
				}

				// log('planSessionRelations', planSessionRelations);
				callback(null, sessions);

			},
			error: function (error) {
				// An error occurred while fetching one of the objects.
				callback(error, null)
			},
		});
	}

	toggleAddRemoveSessionToPlan(session, callback) {
		if (!session) {
			return;
		}

		Parse.Cloud.run('updateValidPlansForSession', { planIds: [{ planId: this.current().id, className: this.current().className }], sessionObjectId: session.id }, {
			success: function (result) {
				callback(null, result);
				log('success updateValidPlansForSession');
			},
			error: function (error) {
				log('error', error);
			}
		});
	}

	addAllSessionsToPlan(sessions, callback) {
		log('addAllSessionsToPlan')
		if (!sessions || !sessions.length) {
			log('no sessions')
			callback(undefined);
		}

		try {
			// [{planId: plan.id, className: plan.className}]
			Parse.Cloud.run('addAllSessionsForValidPlan', { sessionIds: sessions.map(session => session.id), planInfo: { planId: this.current().id, className: this.current().className } }, {
				success: function (result) {
					// callback(null, result);
					log('success addAllSessionsForValidPlan');
					callback(null, result);
				},
				error: function (error) {
					log('error', error);
					callback(error);
				}
			});

		} catch (e) {
			log(e);
		}
	}

	transformStudentPaymentPlan(upp) {
		try {
			// log('upp', upp);
			var student = upp.get('buyer');

			if (!student) {
				return false;
			}

			student.userPaymentPlan = upp;
			var plan = upp.get("ticket");
			if (plan) {
				student.userPaymentPlan.entries_left = plan ? (plan.get('entries_number') - upp.get('punched_count') || 0) : 0;
				student.userPaymentPlan.entries_number = plan.get('entries_number');
			}

			student.userPaymentPlan.entries_used = upp.get('punched_count') || 0;
			student.payment = upp.get(config.ENV === 'development' ? 'payment_debug' : 'payment');

			return student;
		} catch (e) {
			log(e);
			return false;
		}
	}

	getUserPaymentPlanEntriesLeft(upp) {
		var plan = upp.get('ticket') || upp.get('membership');
		return plan.get('entries_number') - (upp.get('punched_count') || 0);
	}

	getPlanEntriesNumber(upp) {
		if (upp.get('ticket')) {
			var plan = upp.get('ticket');
			return plan.get('entries_number');
		} else return 0;
	}

	isUserPaymentPlanDateEnded(upp) {
		var plan = upp.get('ticket') || upp.get('membership');
		// return moment(upp.get('expiration_date')).isAfter(new Date());
		return moment(new Date()).isAfter(upp.get('expiration_date').setHours(23, 59, 59, 0));
	}

	isUserPaymentPlanPunchesEnded(upp) {
		var plan = upp.get('ticket');
		return this.getUserPaymentPlanEntriesLeft(upp) <= 0;
	}

	isUserPaymentPlanPaid(upp) {
		return upp.get(config.ENV === 'development' ? 'payment_debug' : 'payment');
	}

	isUserPaymentPlanValid(upp) {
		try {
			var plan = upp.get('ticket') || upp.get('membership');

			if (upp.get('ticket')) {
				// log("check ticket", upp)
				return moment(upp.get('expiration_date').setHours(23, 59, 59, 0)).isAfter(new Date()) && this.getUserPaymentPlanEntriesLeft(upp) > 0;
			} else {
				// log("check membership", upp)
				return moment(upp.get('expiration_date').setHours(23, 59, 59, 0)).isAfter(new Date());
			}
		} catch (e) {
			log(e)
			return false
		}
	}

	getStudentPlan(student, teacherId, fromDate, toDate, unrefundedSessions, insightPlans, callback) {
		log('getStudentPlan')
		return new Promise((resolve, reject) => {
			var UserPaymentPlan = Parse.Object.extend('UserPaymentPlan');
			var query = new Parse.Query(UserPaymentPlan);

			if (insightPlans) {
				query.containedIn('objectId', insightPlans);
			} else {
				var owner;
				if (teacherId && teacherId !== Parse.User.current().id) {
					owner = new Parse.User({
						id: teacherId
					});
				} else {
					owner = Parse.User.current();
				}

				query.equalTo('owner', owner);
				if (student && student.id != teacherId) {
					log('getStudentPlan student', student)
					query.equalTo('buyer', student);
				}
				
				if (fromDate) {
					query.greaterThanOrEqualTo('createdAt', fromDate);
				}
				if (toDate) {
					query.lessThanOrEqualTo('createdAt', toDate);
				}
			}

			query.include('ticket');
			query.include('membership');
			query.notEqualTo('is_medidate_membership', true);
			query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
			query.include('payments');
			query.descending('createdAt');
			query.find({
				success: (res) => {
					log('getStudentPlan success get plans for student', res.length)
					var results = {
						tickets: [],
						memberships: [],
						frozenTickets: [],
						frozenMemberships: [],
						finishedTickets: [],
						finishedMemberships: [],
						frozenPlansMap: {}
					}
					try {
						res.forEach((upp) => {
							try {
								results.frozenPlansMap['freeze_' + upp.id] = upp.get("frozen");
								if (upp.get('ticket')) {
									if (this.isUserPaymentPlanValid(upp)) {
										log('getStudentPlan upp ticket');
										results.tickets.push(upp);
									} else {
										log('getStudentPlan upp ticket finished');
										results.finishedTickets.push(upp);
									}
								} else if (upp.get('membership')) {
									if (this.isUserPaymentPlanValid(upp)) {
										log('getStudentPlan upp membership');
										results.memberships.push(upp);
									} else {
										log('getStudentPlan upp membership finished');
										results.finishedMemberships.push(upp);
									}
								}
							} catch (error) {
								log('getStudentPlan error', error);
							}
						});
					} catch (error) {
						log('getStudentPlan error', error);
					}
					// log('getStudentPlan results', JSON.stringify(results));

					if (unrefundedSessions) {
						this.getUnrefundedSessionsDataForPlans(res, (err, res2) => {

							if (err) {
								log('getUnrefundedSessionsDataForPlans error', err);
								if (callback) {
									callback(null, results)
								}
								resolve(results)
							} else if (res2) {
								// log('getUnrefundedSessionsDataForPlans', res2);
								res.forEach((upp) => {
									if (res2[upp.id]) {
										upp.unrefundedSessions = res2[upp.id];
									}
								});
								// log('getUnrefundedSessionsDataForPlans res', res);
								if (callback) {
									callback(null, results)
								}
								resolve(results)
							}
						});
					} else {
						if (callback) {
							callback(null, results)
						}
						resolve(results)
					}

				},
				error: function (error) {
					callback(error, null)
				}
			});
		});
	}

	getAllPlans(teacherId, fromDate, toDate, callback) {
		log('getAllPlans')
		return new Promise((resolve, reject) => {
			var UserPaymentPlan = Parse.Object.extend('UserPaymentPlan');
			var query = new Parse.Query(UserPaymentPlan);

			var owner;
			if (teacherId && teacherId !== Parse.User.current().id) {
				owner = new Parse.User({
					id: teacherId
				});
			} else {
				owner = Parse.User.current();
			}

			query.equalTo('owner', owner);
			query.include('ticket');
			query.include('membership');
			query.notEqualTo('is_medidate_membership', true);
			query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
			query.include('payments');
			query.descending('createdAt');
			if (fromDate) {
				query.greaterThanOrEqualTo('createdAt', fromDate);
			}
			if (toDate) {
				query.lessThanOrEqualTo('createdAt', toDate);
			}

			query.find({
				success: (res) => {
					log('getAllPlans success get plans for student', res.length)
					if (callback) {
						callback(null, res)
					}
					resolve(res)
				},
				error: function (error) {
					callback(error, null)
				}
			});
		});
	}

	getAllMemberships(teacherId, fromDate, toDate, callback) {
		log('getAllPlans')
		return new Promise((resolve, reject) => {
			var UserPaymentPlan = Parse.Object.extend('UserPaymentPlan');
			var query = new Parse.Query(UserPaymentPlan);

			var owner;
			if (teacherId && teacherId !== Parse.User.current().id) {
				owner = new Parse.User({
					id: teacherId
				});
			} else {
				owner = Parse.User.current();
			}

			query.equalTo('owner', owner);
			query.include('membership');
			query.exists('membership');
			query.notEqualTo('is_medidate_membership', true);
			query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
			query.include('payments');
			query.descending('createdAt');
			if (fromDate) {
				query.greaterThanOrEqualTo('createdAt', fromDate);
			}
			if (toDate) {
				query.lessThanOrEqualTo('createdAt', toDate);
			}

			query.limit(1000);
			query.find({
				success: (res) => {
					log('getAllPlans success get plans for student', res.length)
					if (callback) {
						callback(null, res)
					}
					resolve(res)
				},
				error: function (error) {
					callback(error, null)
				}
			});
		});
	}

	getAllTickets(teacherId, fromDate, toDate, callback) {
		log('getAllPlans')
		return new Promise((resolve, reject) => {
			var UserPaymentPlan = Parse.Object.extend('UserPaymentPlan');
			var query = new Parse.Query(UserPaymentPlan);

			var owner;
			if (teacherId && teacherId !== Parse.User.current().id) {
				owner = new Parse.User({
					id: teacherId
				});
			} else {
				owner = Parse.User.current();
			}

			query.equalTo('owner', owner);
			query.include('membership');
			query.exists('membership');
			query.notEqualTo('is_medidate_membership', true);
			query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
			query.include('payments');
			query.descending('createdAt');
			if (fromDate) {
				query.greaterThanOrEqualTo('createdAt', fromDate);
			}
			if (toDate) {
				query.lessThanOrEqualTo('createdAt', toDate);
			}

			query.limit(1000);
			query.find({
				success: (res) => {
					log('getAllPlans success get plans for student', res.length)
					if (callback) {
						callback(null, res)
					}
					resolve(res)
				},
				error: function (error) {
					callback(error, null)
				}
			});
		});
	}

	refundPlan(upp, session, callback) {
		Parse.Cloud.run('refundPlan', { uppId: upp.id, sessionId: session.id }, {
			success: function (result) {
				log('success getUnrefundedDataFromPlans');
				callback(null, result);
			},
			error: function (error) {
				log('error', error);
				callback(error);
			}
		});
	}

	getUnrefundedSessionsDataForPlans(upps, callback) {
		log('getUnrefundedSessionsDataForPlans');
		var planIds = upps.map((upp) => { return upp.id });
		Parse.Cloud.run('getUnrefundedDataFromPlans', { planIds }, {
			success: function (result) {
				// callback(null, result);
				log('success getUnrefundedDataFromPlans');
				callback(null, result);
			},
			error: function (error) {
				log('error', error);
				callback(error);
			}
		});
	}

	getPlanStudents(callback, limit) {
		var UserPaymentPlan = Parse.Object.extend('UserPaymentPlan');
		var query = new Parse.Query(UserPaymentPlan);
		query.equalTo(this.currentType().toLowerCase(), this.current());
		query.equalTo('owner', Parse.User.current());
		query.include('buyer');
		query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
		query.include('payments');
		query.include('payment.entries_used');
		query.include('payment_debug.entries_used');
		query.include('payment.payment_method_type');
		query.include('payment_debug.payment_method_type');
		query.include('payment.coupon');
		query.include('payment_debug.coupon');
		// query.notEqualTo('is_medidate_membership', true);
		query.ascending('updatedAt');
		query.limit(limit ? limit : 500);

		query.find({
			success: (res) => {
				// log('success get plan students', res)
				var results = _.without(res.map((upp) => {
					return this.transformStudentPaymentPlan(upp);
				}), false);
				log(results)
				callback(null, results)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getSinglePlanStudent(student, callback) {
		var UserPaymentPlan = Parse.Object.extend('UserPaymentPlan');
		var query = new Parse.Query(UserPaymentPlan);
		query.equalTo("buyer", student);
		query.equalTo('owner', Parse.User.current());
		query.equalTo(this.currentType().toLowerCase(), this.current());
		query.include('buyer');
		query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
		query.include('payment.entries_used');
		query.include('payment_debug.entries_used');
		query.include('payment.payment_method_type');
		query.include('payment_debug.payment_method_type');
		// query.notEqualTo('is_medidate_membership', true);
		query.descending('expiration_date');

		query.find({
			success: (userPaymentPlans) => {
				log('success get single plan for student', JSON.stringify(userPaymentPlans[0]))
				var result = this.transformStudentPaymentPlan(userPaymentPlans[0]);
				log(result)
				callback(null, result)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getFilterPlanStudents(filteredStudents, callback) {
		var UserPaymentPlan = Parse.Object.extend('UserPaymentPlan');
		var query = new Parse.Query(UserPaymentPlan);
		query.equalTo(this.currentType().toLowerCase(), this.current());
		query.equalTo('owner', Parse.User.current());
		query.containedIn('buyer', filteredStudents)
		query.include('buyer');
		query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
		query.include('payment.entries_used');
		query.include('payment_debug.entries_used');
		query.include('payment.payment_method_type');
		query.include('payment_debug.payment_method_type');
		query.include('payment.coupon');
		query.include('payment_debug.coupon');
		// query.notEqualTo('is_medidate_membership', true);

		query.find({
			success: (res) => {
				// log('success get plan students', res)
				var results = _.without(res.map((upp) => {
					return this.transformStudentPaymentPlan(upp);
				}), false);
				log(results)
				callback(null, results)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	addSessionToUserPaymentPlan(userPaymentPlan, session, unrefunded, callback) {
		if (!userPaymentPlan || !session) {
			return;
		}

		Parse.Object.fetchAllIfNeeded(userPaymentPlan, {
			success: (res) => {
				var enteredSessionsRelation = unrefunded ? res.relation("unrefunded_sessions") : res.relation("entered_sessions");
				var params = {};

				enteredSessionsRelation.add(session);
				if (userPaymentPlan.get('ticket')) {
					var entries_number = userPaymentPlan.get('ticket').get('entries_number');
					params.punched_count = (res.get('punched_count') || 0) + 1;
					params.last_punch = entries_number - params.punched_count === 0;
				}

				res.save(params, {
					success: (res) => {
						if (userPaymentPlan.get('ticket')) {
							//NOTICE Future developer!
							//This emailCall should ONLY be used if the TEACHER punched the ticket for the student
							//emailType 18
							// Noti.sendTeacherPunchedTicketForStudent(userPaymentPlan);
						}

						callback(null, res);
					},
					error: function (error) {
						callback(error);
					}
				})
			},
			error: () => {

			}
		})
	}

	removeSessionFromUserPaymentPlan(userPaymentPlan, session, unrefunded, callback) {
		if (!userPaymentPlan || !session) {
			return;
		}

		Parse.Object.fetchAllIfNeeded(userPaymentPlan, {
			success: (res) => {
				var enteredSessionsRelation = unrefunded ? res.relation("unrefunded_sessions") : res.relation("entered_sessions");
				var params = {};

				enteredSessionsRelation.remove(session);
				if (res.get('ticket')) {
					var entries_number = res.get('ticket').get('entries_number');
					params.punched_count = (res.get('punched_count') || 0) - 1;
					params.last_punch = entries_number - params.punched_count === 0;
				}

				res.save(params, {
					success: (res) => {
						callback(null, res);
					},
					error: function (error) {
						callback(error);
					}
				})
			},
			error: () => {

			}
		})
	}

	addStudentToPlan(plan, student, callback) {
		log('addStudentToPlan', plan.className.toLowerCase())
		log('addStudentToPlan', plan)
		log('addStudentToPlan', student)
		var UserPaymentPlan = Parse.Object.extend('UserPaymentPlan');
		var userPaymentPlan = new UserPaymentPlan();

		var studentPointer = new Parse.User({
			id: student.id
		});

		var ownerPointer = new Parse.User({
			id: Parse.User.current().id
		});

		//TODO weeks/months
		var newDate = new Date(moment().add(plan.get('expiration_period'), plan.get('expiration_type') == 1 ? 'months' : 'weeks').endOf('day'));
		log("New Payment Plane Date", newDate)
		log('studentPointer', studentPointer)
		userPaymentPlan.save({
			[plan.className.toLowerCase()]: plan,
			buyer: studentPointer,
			owner: ownerPointer,
			expiration_date: newDate
		}, {
			success: (res) => {
				log('userPaymentPlan create success', res);
				User.addFollower(studentPointer);
				var data = this.transformStudentPaymentPlan(res);
				callback(null, data);
			},
			error: function (error) {
				log('Failed to create object', error);
				callback(error);
			}
		});
	}

	removeStudentFromPlan(student, callback) {
		var UserPaymentPlan = Parse.Object.extend('UserPaymentPlan');
		var query = new Parse.Query(UserPaymentPlan);
		query.equalTo(this.currentType().toLowerCase(), this.current());
		query.equalTo('buyer', student);

		query.find({
			success: (results) => {
				log('success find')
				results.map((userPaymentPlan) => {
					userPaymentPlan.destroy({
						success: function (res) {
							log('success destroy', res)
							callback(null, res);
						},
						error: function (error) {
							log('Failed to destroy object, with error code: ' + error.message);
							callback(error);
						}
					});
				})
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getPossibleUserSubscriptions(callback) {
		log('getPossibleUserSubscriptions')
		var medidateMasterId = config.ENV === 'development' ? 'wUxJqBgqNE' : '0h86psZ73i';

		var query = new Parse.Query('Membership');

		query.equalTo('owner', Parse.User.createWithoutData(medidateMasterId));
		query.equalTo('is_medidate_membership', true);
		query.equalTo('active', true);
		if (Parse.User.current().get('bank_country') === 'Israel') {
			query.equalTo('currency', 'ILS');
		} else {
			query.equalTo('currency', 'USD');
		}
		query.notEqualTo('objectId', config.TRIAL_MEMBERSHIP_ID);
		// query.descending('expiration_period');
		query.include('owner');
		query.include('favorite_users');
		query.descending('expiration_period');

		query.find({
			success: (res) => {
				log('success get memberhips', res)
				callback(null, res)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getTrialMembershipAndSaveToUser(student, callback) {
		log('getTrialMembershipAndSaveToUser', student)
		// var medidateMasterId = config.ENV === 'development' ? 'wUxJqBgqNE' : '0h86psZ73i';
		var medidateTrialMembershipId = config.ENV === 'development' ? '7YXpeSd10U' : 'bBER8HStm6';

		var query = new Parse.Query('Membership');
		query.equalTo('objectId', medidateTrialMembershipId);
		query.include('memberhip');
		query.include('owner');

		query.find({
			success: (res) => {
				log('success get memberhips', res);
				let plan = res[0];
				this.createNewUserSubscription(plan, plan.get('owner'), student, callback)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	createNewUserSubscription(plan, owner, student, callback) {
		log('createNewUserSubscription', plan, student)
		var UserPaymentPlan = Parse.Object.extend('UserPaymentPlan');
		var userPaymentPlan = new UserPaymentPlan();

		//TODO weeks/months
		userPaymentPlan.save({
			[plan.className.toLowerCase()]: plan,
			buyer: student,
			owner: owner,
			is_medidate_membership: true,
			expiration_date: new Date(moment().add(plan.get('expiration_period'), plan.get('expiration_type') == 1 ? 'months' : 'weeks').endOf('day'))
		}, {
			success: (upp) => {
				log('subscription create success', upp)

				Parse.User.current().set("subscription", upp);
				Parse.User.current().save({}, {
					success: (res) => {
						log('subscription add to user successfully', res)
						Parse.Cloud.run('handleAffiliationForNewStudioIfNeeded', { userId: Parse.User.current().id }, {
							success: res => {
								log(res)
							},
							error: error => {
								log('Failed to cancel UserPaymentPlan or Product, with error code: ' + error);
							}
						});
						callback(null, data);
					},
					error: function (error) {
						log('Failed to create object, with error code: ' + error.message);
						callback(error);
					}
				});
			},
			error: function (error) {
				log('Failed to create object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	addStudentToCurrentPlan(student, callback) {
		var Plan = Parse.Object.extend(this.current().className);
		var plan = new Plan();
		this.current().increment("purchased_count");
		this.current().save({}, {
			success: (plan) => {
				this.addStudentToPlan(this.current(), student, callback);
			},
			error: function (error) {
				this.addStudentToPlan(this.current(), student, callback);
			}
		})
	}

	removeStudentfromCurrentPlan(userPaymentPlan, callback) {

		var params = { isProduct: false, uppId: userPaymentPlan.id };
		log('removeStudentfromCurrentPlan params', params);
		Parse.Cloud.run('cancelProduct', params, {
			success: res => {
				log(res)
				log('Cancelled Successfully', res)
				callback(null, res);
			},
			error: error => {
				log('Failed to cancel UserPaymentPlan or Product, with error code: ' + error);
				callback(error);
			}
		});
		// this.current().increment("purchased_count", -1);
		// this.current().save();
		// userPaymentPlan.destroy({
		// 	success: function (res) {
		// 		log('success destroy', res)
		// 		callback(null, res);
		// 	},
		// 	error: function (error) {
		// 		log('Failed to destroy object, with error code: ' + error.message);
		// 		callback(error);
		// 	}
		// });
	}

	freezeUserPaymentPlan(weeksNumber, userPaymentPlan, callback) {
		log('freezeUserPaymentPlan')
		if (!userPaymentPlan) {
			return;
		}

		var freezeDate = new Date();
		userPaymentPlan.set("freeze_date", freezeDate);
		userPaymentPlan.set("weeks_to_unfreeze", weeksNumber);
		userPaymentPlan.set("unfreeze_date", this.getAutoUnfreezeUserPaymentPlanDate(userPaymentPlan));
		userPaymentPlan.set("frozen", true);

		userPaymentPlan.save({
			success: function (userPaymentPlan) {
				log('UserPaymentPlan freeze success', userPaymentPlan)
				callback(null, userPaymentPlan);
			},
			error: function (error) {
				log('Failed to freeze UserPaymentPlan', error);
				callback(error);
			}
		});
	}

	unFreezeUserPaymentPlan(userPaymentPlan, callback) {
		log('unFreezeUserPaymentPlan')
		if (!userPaymentPlan) {
			return;
		}

		userPaymentPlan.set("expiration_date", this.getFrozenUserPaymentPlanExpiration(userPaymentPlan));
		userPaymentPlan.unset("weeks_to_unfreeze");
		userPaymentPlan.unset("frozen");
		userPaymentPlan.unset("freeze_date");
		userPaymentPlan.unset("unfreeze_date");
		userPaymentPlan.save({}, {
			success: function (userPaymentPlan) {
				log('UserPaymentPlan unfrozen success', userPaymentPlan)
				Parse.Cloud.run('sendEmailAlertPlanHasBeenUnfrozen', { userPaymentPlanId: userPaymentPlan.id });
				callback(null, userPaymentPlan);
			},
			error: function (error) {
				log('Failed to unfreeze UserPaymentPlan', error);
				callback(error);
			}
		});
	}

	getFrozenUserPaymentPlanExpiration(userPaymentPlan) {
		log('getFrozenUserPaymentPlanExpiration')
		if (!userPaymentPlan) {
			return;
		}

		var freezeDate = moment(userPaymentPlan.get("freeze_date"));
		var now = moment(new Date());
		var daysDifference = moment.duration(now.diff(freezeDate)).asDays();
		daysDifference = parseInt(Math.abs(daysDifference));
		log('daysDifference', daysDifference)

		var expirationDate = moment(userPaymentPlan.get("expiration_date"));
		log('expirationDate', expirationDate)

		var newExpirationDate = expirationDate.add(daysDifference, 'days').toDate();
		log('newExpirationDate', newExpirationDate)

		return newExpirationDate;
	}

	getAutoUnfreezeUserPaymentPlanDate(userPaymentPlan) {
		log('getAutoUnfreezeUserPaymentPlanDate')
		if (!userPaymentPlan) {
			return;
		}

		var freezeDate = moment(userPaymentPlan.get("freeze_date"));
		var autoUnfreezeWeeks = userPaymentPlan.get("weeks_to_unfreeze")


		var unfreezeDate = freezeDate.add(autoUnfreezeWeeks, 'weeks').toDate();
		log('unfreezeDate', unfreezeDate)

		return unfreezeDate;
	}
}

export default new Plan();
