// Naming is shorten because Notification is a reserved name in Javascript
import Parse from 'parse';
import BaseModel from './BaseModel';
import { I18n } from 'react-i18nify';
import Messages from './Messages';
import _ from 'underscore';
import { log } from './Log';
import { Label } from 'reactstrap';
import Studio from './Studio';

const NOTIFICATIONS_TYPES = {
  PushTypeSessionUpdated: 0,
  PushTypeSessionCreated: 1,
  PushTypeNewAttender: 2, 		//A new attender joined a session through the app
  PushTypeNewFollower: 3, 		//A new student decide to follow the teacher through the app
  PushTypeSessionDeleted: 4,
  PushTypeMessage: 5, 		//A new message inside a specific session chat group was written
  PushTypeFollowersMessage: 6, 	//A new message inside all followers chat group was written
  PushTypeRequestRefund: 7,		//A user requested a refund for a session
  PushTypeRefunded: 8,
  PushTypeRefundedDeleted: 9,
  PushTypePrivateMessage: 10,	//A new message was sent privately to the teacher
  PushTypeNearByUsers: 11,
  PushTypeInvitedUserToSession: 12,
  PushTypeInvitedUserToSessionByLocation: 13,
  PushTypeAssociatedTeacherWithStudio: 14,	//A Studio associated the teacher with it
  PushTypeSearchingForPartnersNearYou: 15,
  PushTypeStudioMessage: 16,
  PushTypeLogin: 17,
  SessionMinimumReq: 18
}

const EMAIL_TYPES = {
  InviteFriends: 0,
  TeacherRegisteredPayments: 1,
  StudentPaidForSession: 2,
  TeacherGotPaidForSession: 3,
  StudentRefundRequest: 4,
  SessionDeletedUserRefunded: 5,
  InviteStudentToSession: 6,
  NewAttenderInSession: 7,
  UserWonCampaign: 8,
  StudentPaidForTeacher: 9,
  TeacherGotPaid: 10,
  StudentPurchaseTicket: 11,
  TeacherGotPaidForTicket: 12,
  StudentPurchaseMembership: 13,
  TeacherGotPaidForMembership: 14,
  NewPrivateMessage: 15,
  TeacherAddedStudent: 16,
  StudioAddedTeacher: 17,
  TeacherPunchedTicket: 18,
  TeacherAddedExistingStudent: 22,
  SessionMinimumReq: 23,
  StudentLeftSession: 24,
  TeacherStudentHealthForm: 25,
  TeacherStudentHealthFormFilled: 26,
  StudentSessionAboutToBegin: 27,
  StudentSessionCoronaForm: 28,
  StudentPurchasedProductFromClient: 29,
  StudentPurchasedProductFromClientConfirmation: 30,
  StudentEnteredWaitingList: 31,
  StudentJoinPolicyRange: 32,
  StudentUnjoinPolicyRange: 33,
  StudentSignedUpToStudio: 34,
  StudioTermsForm: 35,
  StudentAddedAsFrequent: 36,
  StudeioRegistered: 37,
  PaymentFailed: 38,
  AffiliationFinished: 39,
  GrowSupportEmail : 40,
  TaskUpdatedByTeacher: 41,
  TaskAssignedToTeacher: 42
}

const getUserPageUrl = (noti) => {
  return '/dashboard/users/' + noti.get('push_object_id') + '/' + Parse.User.current().id;
}

const getMessagePageUrl = (noti) => {
  var notificationTypesMessagesSubRoutesEnum = {
    5: 'sessions-chats',
    6: 'all-students',
    10: 'private-chats'
  }

  return '/dashboard/messages/' + notificationTypesMessagesSubRoutesEnum[noti.get('push_type')] + '/' + '?chat=' + noti.get('push_object_id');
}

const NOTIFICATIONS_ACTIONS_URL = {
  2: function (noti) {
    return '/dashboard/sessions/' + noti.get('push_object_id') + '/edit';
  },
  3: getUserPageUrl,
  5: getMessagePageUrl,
  6: getMessagePageUrl,
  7: getUserPageUrl,
  10: getMessagePageUrl,
  14: getUserPageUrl,
  24: function (noti) {
    return '/dashboard/users/' + noti.get('push_object_id') + '/' + Parse.User.current().id;
  },
}

const QUERIED_PUSH_TYPES = [
  2, 3, 5, 6, 7, 10, 14, 24
];

var Noti = class Noti extends BaseModel {
  constructor() {
    super();
    this.NOTIFICATIONS_ACTIONS_URL = NOTIFICATIONS_ACTIONS_URL;
    this.NOTIFICATIONS_TYPES = NOTIFICATIONS_TYPES;
  }

  markNotiAsRead(noti, callback) {
    log('markNotiAsRead', noti);
    try {
      noti.relation("notified_users").remove(Parse.User.current());
      noti.relation("read_users").add(Parse.User.current());

      noti.save({}, {
        success: (res) => {
          log('marked NotiAsRead');
          callback(undefined, res)
        },
        error: (err) => {
          log('markNotiAsRead error', err);
          callback(err, res)
        }
      })
    } catch (error) {
      log('error', error);
    }
  }

  markNotisAsRead(notis, callback) {
    log('markNotisAsRead', notis);
    try {
      for (let i = 0; i < notis.length; i++) {
        const noti = notis[i];
        noti.relation("notified_users").remove(Parse.User.current());
        noti.relation("read_users").add(Parse.User.current());
      }

      Parse.Object.saveAll(notis, {
        success: function (notis) {
          log('marked NotisAsRead');
          callback(undefined, notis)
        },
        error: function (error) {
          log('mark NotisAsRead error', error);
          callback(err)
        },
      });
    } catch (error) {
      log('error', error);
    }
  }

  getReadNotifications(callback) {
    var Noti = Parse.Object.extend('Notification');
    var query = new Parse.Query(Noti);
    query.equalTo('read_users', Parse.User.current());
    query.descending('createdAt');
    query.containedIn('push_type', QUERIED_PUSH_TYPES)
    query.limit(4);

    query.find({
      success: (results) => {
        callback(null, results)
      },
      error: function (error) {
        callback(error, null)
      }
    });
  }

  getNewNotifications(callback) {
    var Noti = Parse.Object.extend('Notification');
    var query = new Parse.Query(Noti);
    query.equalTo('notified_users', Parse.User.current());
    query.descending('createdAt');
    query.containedIn('push_type', QUERIED_PUSH_TYPES)

    query.find({
      success: (results) => {
        callback(null, results)
      },
      error: function (error) {
        callback(error, null)
      }
    });
  }

  sendStudioAddedTeacherPushNotification(studio, teacherObject) {
    var creatorFullName = Parse.User.current().get("first_name") +
      " " + Parse.User.current().get("last_name");

    var attenderIds = [];
    attenderIds.push(teacherObject.id);

    var pushParams = {
      creatorOfPushId: Parse.User.current().id,
      creatorOfPushFullName: creatorFullName,
      attenderIdsList: attenderIds,
      pushObjectId: Parse.User.current().id,
      titleOrName: studio.get("title") ? studio.get("title") : creatorFullName,
      pushType: NOTIFICATIONS_TYPES.PushTypeAssociatedTeacherWithStudio
    }

    this.sendPushNotification(pushParams);
  }

  sendSessionCreatedPushNotification(session) {
    var pushParams = {
      newSessionId: session.id,
      sendNearBy: true
    }
    this.sendPushNotification(pushParams);
  }

  sendSessionUpdatePushNotification(session, attenders) {
    var creatorFullName = Parse.User.current().get("first_name") +
      " " + Parse.User.current().get("last_name");

    var attenderIds = [];
    for (var i = 0; i < attenders.length; i++) {
      attenderIds.push(attenders[i].id);
    }

    var pushParams = {
      creatorOfPushId: Parse.User.current().id,
      creatorOfPushFullName: creatorFullName,
      attenderIdsList: attenderIds,
      pushObjectId: session.id,
      titleOrName: session.get("title"),
      pushType: NOTIFICATIONS_TYPES.PushTypeSessionUpdated
    }
    this.sendPushNotification(pushParams);
  }

  sendSessionCancelledPushNotification(session, attenders) {
    var creatorFullName = Parse.User.current().get("first_name") +
      " " + Parse.User.current().get("last_name");

    var attenderIds = [];
    for (var i = 0; i < attenders.length; i++) {
      attenderIds.push(attenders[i].id);
    }

    var pushParams = {
      creatorOfPushId: Parse.User.current().id,
      creatorOfPushFullName: creatorFullName,
      attenderIdsList: attenderIds,
      pushObjectId: session.id,
      titleOrName: session.get("title"),
      pushType: NOTIFICATIONS_TYPES.PushTypeSessionDeleted
    }
    this.sendPushNotification(pushParams);
  }

  sendChatPushNotification(params) {
    var pushType = params.pushType;
    if (pushType == NOTIFICATIONS_TYPES.PushTypePrivateMessage) {
      //Private Chat
      this.sendPrivateChatPushNotification(params);
    } else if (pushType == NOTIFICATIONS_TYPES.PushTypeFollowersMessage) {
      //Followers Chat
      this.sendFollowersChatPushNotification(params);
    } else if (pushType == NOTIFICATIONS_TYPES.PushTypeMessage) {
      //Session Chat
      this.sendSessionChatPushNotification(params);
    } else if (pushType == NOTIFICATIONS_TYPES.PushTypeStudioMessage) {
      //Studio Chat
      this.sendStudioChatPushNotification(params);
    }
  }

  sendPrivateChatPushNotification(params) {
    var creatorFullName = Parse.User.current().get("first_name") +
      " " + Parse.User.current().get("last_name");

    var pushParams = {
      creatorOfPushId: Parse.User.current().id,
      creatorOfPushFullName: creatorFullName,
      pushObjectId: params.relationParentObject.id,
      titleOrName: creatorFullName,
      messageId: params.messageId,
      messageText: creatorFullName + ": " + params.messageText,
    }

    var attenderIds = [];
    var otherUser = Messages.getPrivateChatRecipient(params.relationParentObject);

    attenderIds.push(otherUser.id);

    pushParams.attenderIdsList = attenderIds;
    pushParams.pushType = NOTIFICATIONS_TYPES.PushTypePrivateMessage;

    this.sendPushNotification(pushParams);
  }

  sendFollowersChatPushNotification(params) {
    var creatorFullName = Parse.User.current().get("first_name") +
      " " + Parse.User.current().get("last_name");

    var pushParams = {
      creatorOfPushId: Parse.User.current().id,
      creatorOfPushFullName: creatorFullName,
      pushObjectId: params.relationParentObject.id,
      titleOrName: creatorFullName + " " + showTextByBusinessType('messages.FOLLOWERS_SUFFIX'),
      messageId: params.messageId,
      messageText: creatorFullName + ": " + params.messageText,
    }

    var attenderIds = [];
    var Follow = Parse.Object.extend('Follow');
    var query = new Parse.Query(Follow);
    query.equalTo('to', params.relationParentObject)
    query.include('from')
    query.find({
      success: (followers) => {
        for (var i = 0; i < followers.length; i++) {
          var followerUser = followers[i].get("from");
          attenderIds.push(followerUser.id);
        }

        pushParams.attenderIdsList = attenderIds;

        pushParams.pushType = NOTIFICATIONS_TYPES.PushTypeFollowersMessage;

        this.sendPushNotification(pushParams);
      },
      error: function (error) {
        log(error)
      }
    });
  }

  sendSessionChatPushNotification(params) {
    var creatorFullName = Parse.User.current().get("first_name") +
      " " + Parse.User.current().get("last_name");

    var pushParams = {
      creatorOfPushId: Parse.User.current().id,
      creatorOfPushFullName: creatorFullName,
      pushObjectId: params.relationParentObject.id,
      titleOrName: params.relationParentObject.get("title"),
      messageId: params.messageId,
      messageText: creatorFullName + ": " + params.messageText,
    }

    var attenderIds = [];
    var querySessionAttenders = params.relationParentObject.relation("attenders").query();
    querySessionAttenders.find({
      success: (attenders) => {
        for (var i = 0; i < attenders.length; i++) {
          attenderIds.push(attenders[i].id);
        }

        pushParams.attenderIdsList = attenderIds;
        pushParams.pushType = NOTIFICATIONS_TYPES.PushTypeMessage;
        this.sendPushNotification(pushParams);
      },
      error: (error) => {
        log(error)
      }
    });
  }

  sendStudioChatPushNotification(params) {
    var creatorFullName = Parse.User.current().get("first_name") +
      " " + Parse.User.current().get("last_name");

    var pushParams = {
      creatorOfPushId: Parse.User.current().id,
      creatorOfPushFullName: creatorFullName,
      pushObjectId: params.relationParentObject.id,
      titleOrName: params.relationParentObject.get("title"),
      messageId: params.messageId,
      messageText: creatorFullName + ": " + params.messageText,
    }

    var attenderIds = [];
    var queryStudioTeachers = params.relationParentObject.relation("associated_teachers").query();
    queryStudioTeachers.notEqualTo("objectId", Parse.User.current().id);
    queryStudioTeachers.find({
      success: (teachers) => {
        for (var i = 0; i < teachers.length; i++) {
          attenderIds.push(teachers[i].id);
        }

        pushParams.attenderIdsList = attenderIds;
        pushParams.pushType = NOTIFICATIONS_TYPES.PushTypeStudioMessage;
        this.sendPushNotification(pushParams);
      },
      error: (error) => {
        log(error)
      }
    });
  }

  sendTeacherStudentHealthCare(params, newUser) {
    log('sendTeacherStudentHealthCare');
    log('sendTeacherStudentHealthCare ' + newUser.id)
    log('sendTeacherStudentHealthCare ' + params.firstName)
    log('sendTeacherStudentHealthCare ' + params.email)

    var creatorFullName = Parse.User.current().get("first_name") +
      " " + Parse.User.current().get("last_name");

    var emailParams = {
      emailType: EMAIL_TYPES.TeacherStudentHealthForm,
      fromName: creatorFullName,
      fromEmail: "no-reply@medidatewith.me",
      toName: params.firstName,
      toEmail: params.email,
      userId: newUser.id
    }

    this.sendEmail(emailParams);
  }

  sendStudioTerms(params, newUser) {
    log('sendStudioTerms');
    log('sendStudioTerms ' + newUser.id)
    log('sendStudioTerms ' + params.firstName)
    log('sendStudioTerms ' + params.email)

    var creatorFullName = Parse.User.current().get("first_name") +
      " " + Parse.User.current().get("last_name");

    var emailParams = {
      emailType: EMAIL_TYPES.StudioTermsForm,
      fromName: creatorFullName,
      fromEmail: "no-reply@medidatewith.me",
      toName: params.firstName,
      toEmail: params.email,
      userId: newUser.id
    }

    this.sendEmail(emailParams);
  }

  sendStudentSessionCoronaStatement(user, session) {
    log('sendStudentSessionCoronaStatement');

    var creatorFullName = Parse.User.current().get("first_name") +
      " " + Parse.User.current().get("last_name");

    var emailParams = {
      emailType: EMAIL_TYPES.StudentSessionCoronaForm,
      fromName: creatorFullName,
      fromEmail: "no-reply@medidatewith.me",
      toName: user.get('first_name'),
      toEmail: user.get('username'),
      sessionId: session.id,
      userId: user.id
    }

    this.sendEmail(emailParams);
  }

  sendCompletelyNewUserAddedForSessionOrTeacher(params, newUser) {
    log('sendCompletelyNewUserAddedForSessionOrTeacher', params);
    log('sendCompletelyNewUserAddedForSessionOrTeacher ' + newUser);
    var creatorFullName;

    var fourLetterPassword = newUser.id.substring(0, 4);
    log('fourLetterPassword', fourLetterPassword);

    if (params.teacherId) {
      Parse.Cloud.run('getFullUsersFromIds', { userIds: [params.teacherId] }, {
        success: (fullUsers) => {
          if (fullUsers && fullUsers.length > 0) {
            var teacher = fullUsers[0];

            creatorFullName = teacher.get("first_name") +
              " " + teacher.get("last_name");
            var emailParams = {
              emailType: params.existingUser ? EMAIL_TYPES.TeacherAddedExistingStudent : EMAIL_TYPES.TeacherAddedStudent,
              fromName: creatorFullName,
              fromEmail: "no-reply@medidatewith.me",
              toName: newUser.get("first_name"),
              toEmail: params.email ? params.email : newUser.get("email"),
              newUserName: params.email ? params.email : newUser.get("email"),
              newPassword: params.password ? params.password : fourLetterPassword,
              ...(params.password && {
                user_chose_pass: true,
              }),
              ...(params.teacherId && {
                importSenderId: params.teacherId
              }),
              ...(params.forceSend && {
                forceSend: params.forceSend,
              }),
            }
            this.sendEmail(emailParams);
          }
        },
        error: function (error) {
          console.log('error', error);
        }
      });

      Parse.Cloud.run('sendStudioNewStudentRegisteredToStudio', { teacherId: params.teacherId, studentId: newUser.id });

      return;
    } else {
      creatorFullName = Parse.User.current().get("first_name") +
        " " + Parse.User.current().get("last_name");

      var emailParams = {
        emailType: params.existingUser ? EMAIL_TYPES.TeacherAddedExistingStudent : EMAIL_TYPES.TeacherAddedStudent,
        fromName: creatorFullName,
        fromEmail: "no-reply@medidatewith.me",
        toName: newUser.get("first_name"),
        toEmail: params.email ? params.email : newUser.get("email"),
        newUserName: params.email ? params.email : newUser.get("email"),
        newPassword: fourLetterPassword,
        ...(params.forceSend && {
          forceSend: params.forceSend,
        }),
      }

      this.sendEmail(emailParams);
    }
  }
  
  sendNewUserAddedForStudioAsLead(params, newUser) {
    log('sendNewUserAddedForStudioAsLead', params);
    log('sendNewUserAddedForStudioAsLead ' + newUser);
    var creatorFullName;

    Parse.Cloud.run('getFullUsersFromIds', { userIds: [params.teacherId] }, {
      success: (fullUsers) => {
        if (fullUsers && fullUsers.length > 0) {
          var teacher = fullUsers[0];

          creatorFullName = newUser.get("first_name") +
            " " + newUser.get("last_name");
          var emailParams = {
            emailType: EMAIL_TYPES.StudentSignedUpToStudio,
            fromName: I18n._localeKey == 'he' ? 'מדידייט' : 'Medidate',
            fromEmail: "no-reply@medidatewith.me",
            toName: teacher.get("first_name"),
            toEmail: teacher.get("email"),
            studentName: creatorFullName,
            studentEmail: newUser.get("email"),
            archived: true
          }
          this.sendEmail(emailParams);
        }
      },
      error: function (error) {
        console.log('error', error);
      }
    });
  }

  sendCompletelyNewUserAddedForStudioAsTeacher(params, newUser) {
    log('sendCompletelyNewUserAddedForStudioAsTeacher', params);

    var creatorFullName = Parse.User.current().get("studio").get("title");
    log('creatorFullName ' + creatorFullName);

    var fourLetterPassword = newUser.id.substring(0, 4);
    log('fourLetterPassword', fourLetterPassword);

    var emailParams = {
      emailType: EMAIL_TYPES.StudioAddedTeacher,
      fromName: creatorFullName,
      fromEmail: "no-reply@medidatewith.me",
      toName: params.firstName,
      toEmail: params.email,
      newUserName: params.email,
      newPassword: fourLetterPassword,
      deepLink: "https://admin.medidatewith.me",
      ...(params.teacherId && {
        importSenderId: params.teacherId
      }),
    }
    this.sendEmail(emailParams);
  }

  sendTaskAssignedToTeacher(params) {
    log('sendTaskAssignedToTeacher');
    params.emailType = EMAIL_TYPES.TaskAssignedToTeacher;
    this.sendEmail(params);
  }

  sendTaskUpdatedByTeacher(params) {
    log('sendTaskUpdatedByTeacher');
    params.emailType = EMAIL_TYPES.TaskUpdatedByTeacher;
    
    this.sendEmail(params);
  }

  sendTeacherPunchedTicketForStudent(userPaymentPlan) {
    log('sendTeacherPunchedTicketForStudent');

    // var creatorFullName = Parse.User.current().get("first_name") +
    // " " + Parse.User.current().get("last_name");
    //
    // Parse.Object.fetchAllIfNeeded(userPaymentPlan.get("ticket"), {
    //   success: (ticket) => {
    //     var userFromPlan = userPaymentPlan.get("buyer");
    //     var entriesLeft = ticket.get("entries_number") -
    //     userPaymentPlan.get("punched_count");
    //
    //     Parse.Cloud.run('getFullUsersFromIds', {userIds : userFromPlan.id}, {
    //       success: function (fullUsers) {
    //         var receiver = fullUsers[0];
    //
    //         var emailParams = {
    //           emailType : EMAIL_TYPES.TeacherPunchedTicket,
    //           fromName : creatorFullName,
    //           fromEmail : "no-reply@medidatewith.me",
    //           toName : receiver.get("first_name"),
    //           toEmail : receiver.get("email"),
    //           ticketId : ticket.id,
    //           extraText : entriesLeft,
    //           extraDate : userPaymentPlan.get("expiration_date")
    //         }
    //
    //         this.sendEmail(emailParams);
    //       },
    //       error: function (error) {
    //         log('error', error);
    //       }
    //     });
    //   },
    //   error: () => {
    //     log('error', error);
    //   }
    // })
  }

  sendStudioWelcomeEmail(params, newUser) {
    log('sendStudioWelcomeEmail');
    log('sendStudioWelcomeEmail ' + newUser.id)
    log('sendStudioWelcomeEmail ' + params.firstName)
    log('sendStudioWelcomeEmail ' + params.email)

    var emailParams = {
      emailType: EMAIL_TYPES.StudeioRegistered,
      fromName: I18n._localeKey == 'he' ? 'מדידייט' : 'Medidate',
      fromEmail: "no-reply@medidatewith.me",
      toName: params.firstName,
      toEmail: params.email,
      userId: newUser.id,
      isSpecialEventSignup: params.isSpecialEventSignup
    }

    this.sendEmail(emailParams);
  }

  sendPushNotification(pushParams) {
    Parse.Cloud.run('sendPushNotification', pushParams, {
      success: function (result) {
        log('success sendPushNotification');
      },
      error: function (error) {
        log('error', error);
      }
    });
  }

  sendEmail(emailParams) {
    log('sendEmail');
    Parse.Cloud.run('sendEmailsToUsers', emailParams, {
      success: function (result) {
        log('success sendEmailsToUsers');
      },
      error: function (error) {
        log('error', error);
      }
    });
  }
}


export default new Noti();
